export const TeamColor = [
    {"code": "#FF0000", "name": "rouge"},
    {"code": "#FFA500", "name": "orange"},
    {"code": "#FFFF00", "name": "jaune"},
    {"code": "#008000", "name": "vert foncé"},
    {"code": "#00FF00", "name": "vert"},
    {"code": "#ADD8E6", "name": "bleu clair"},
    {"code": "#0000FF", "name": "bleu"},
    {"code": "#800080", "name": "violet"},
    {"code": "#FFC0CB", "name": "rose"},
    {"code": "#000000", "name": "noir"},
    {"code": "#FFFFFF", "name": "blanc"},
    {"code": "#808080", "name": "gris"},
    {"code": "#FF6347", "name": "rouge corail"},
    {"code": "#FF8C00", "name": "orange foncé"},
    {"code": "#FFD700", "name": "or"},
    {"code": "#9ACD32", "name": "vert jaunâtre"},
    {"code": "#7FFFD4", "name": "turquoise"},
    {"code": "#00FFFF", "name": "cyan"},
    {"code": "#4B0082", "name": "indigo"},
    {"code": "#FF69B4", "name": "rose vif"},
    {"code": "#800000", "name": "marron"},
    {"code": "#F0E68C", "name": "jaune pâle"},
    {"code": "#00FF7F", "name": "vert printemps"},
    {"code": "#40E0D0", "name": "turquoise clair"},
    {"code": "#9370DB", "name": "violet moyen"},
    {"code": "#FF1493", "name": "rose vif"},
    {"code": "#FF4500", "name": "orange rouge"},
    {"code": "#32CD32", "name": "vert lime"},
    {"code": "#1E90FF", "name": "bleu dodger"},
    {"code": "#9400D3", "name": "violet foncé"},
    {"code": "#FFB6C1", "name": "rose clair"},
    {"code": "#696969", "name": "gris foncé"},
    {"code": "#DC143C", "name": "rouge cramoisi"},
    {"code": "#20B2AA", "name": "bleu clair de mer"},
    {"code": "#EE82EE", "name": "violet moyen"}
];
